@import url('sanitize.css');

@custom-media --medium (max-width: 999px);
@custom-media --small (max-width: 460px);

html {
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  height: 100%;
  min-height: 620px;
  background: #283237;
  font: normal normal 700 20px/1.5 'Open Sans', sans-serif;
  color: #fff;

  @media (--medium) {
    min-height: 610px;
  }

  @media (--small) {
    min-height: 420px;
  }
}

.container {
  position: relative;
  height: 100%;
  background: #283237 url('./assets/background.jpg') no-repeat fixed center;
  background-size: cover;
}

.background {
  position: absolute;
  width: calc(100% - 240px);
  height: 100%;
  left: 0;
  background: #283237;

  @media (--medium) {
    width: 100%;
  }
}

.content {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
}

.mask {
  position: relative;
  width: 362px;
  margin-bottom: 20px;

  @media (--medium) {
    width: 310px;
  }

  @media (--small) {
    width: 180px;
  }
}

.mask::before {
  position: relative;
  content: '';
  display: block;
  width: auto;
  margin: 1.5%;
  padding-top: 97.56097561%;
  background: url('./assets/background.jpg') no-repeat fixed center;
  background-size: cover;
}

.mask::after {
  position: absolute;
  content: '';
  display: block;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: url('./assets/logo-mask.svg') no-repeat center;
  background-size: cover;
}

h1 {
  margin: 0 0 100px;
  font-size: 58px;
  letter-spacing: -2px;

  @media (--medium) {
    font-size: 52px;
  }

  @media (--small) {
    font-size: 32px;
    letter-spacing: -1px;
  }
}

address {
  position: absolute;
  left: 100px;
  bottom: 50px;
  font-style: normal;
  font-size: 23px;

  @media (--medium) {
    left: 0;
    right: 0;
    bottom: 70px;
    text-align: center;
  }

  @media (--small) {
    font-size: 18px;
  }
}

a {
  display: block;
  color: #ccc;
  text-decoration: none;
}
